<template>
  <MainLayout>
    <div
      v-if="status === 'loading'"
      class="w-full flex items-center justify-center"
    >
      <ToznyLoader class="text-tozny w-24 h-24" />
    </div>
    <div v-if="showGlobalError" class="bg-error text-white p-4 mb-8">
      <p>
        {{ errorMessage }} Reload the page or contact
        <a href="mailto:support@tozny.com" class="text-tozny"
          >support@tozny.com</a
        >
        if the issue doesn't resolve.
      </p>
    </div>
    <div v-if="showError" class="bg-error text-white p-4 mb-8">
      <p>{{ errorMessage }}</p>
    </div>
    <NoGroups
      v-if="statusZero"
      :create-group="createInitialGroup"
      :loading="status === 'zero.adding'"
      :errorMessage="errorMessage"
    />
    <AddGroup
      v-if="statusAdding"
      :create-group="addGroup"
      :loading="status === 'adding'"
      :errorMessage="errorMessage"
      @cancel="transitionStatus('idle')"
    />
    <ListGroups
      v-if="statusIdle"
      :groups="groups"
      :has-more="hasMore"
      @more="loadGroups"
      @select="selectGroup"
      @delete="beginDelete"
      @add="transitionStatus('add')"
    />
    <GroupDeleteDialogue
      :showing="showDeleteDialogue"
      :delete-queue="deleteQueue"
      @confirm="confirmDelete"
      @cancel="cancelDeleteQueue"
    />
  </MainLayout>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

import MainLayout from '@/Common/MainLayout/MainLayout'
import ToznyLoader from '@/Common/ToznyLoader'
import NoGroups from './Components/NoGroups'
import ListGroups from './Components/ListGroups'
import AddGroup from './Components/AddGroup'
import GroupDeleteDialogue from './Components/GroupDeleteDialogue'

export default {
  name: 'Groups',
  components: {
    MainLayout,
    ToznyLoader,
    NoGroups,
    ListGroups,
    AddGroup,
    GroupDeleteDialogue,
  },
  computed: {
    ...mapState('groups', [
      'status',
      'groups',
      'errorMessage',
      'initialized',
      'deleteQueue',
    ]),
    ...mapGetters('groups', ['hasMore']),
    statusZero() {
      return this.status === 'zero' || this.status === 'zero.adding'
    },
    statusIdle() {
      return this.status === 'idle' || this.status === 'idle.delete'
    },
    statusAdding() {
      return this.status === 'add' || this.status === 'adding'
    },
    showDeleteDialogue() {
      return this.status === 'idle.delete'
    },
    showGlobalError() {
      return this.status === 'globalError'
    },
    showError() {
      return this.status === 'idle' && this.errorMessage
    },
  },
  methods: {
    ...mapActions('groups', [
      'initialize',
      'loadGroups',
      'createGroup',
      'selectGroup',
      'transitionStatus',
      'enqueueDelete',
      'cancelDeleteQueue',
      'deleteGroup',
    ]),
    async createInitialGroup(group) {
      await this.transitionStatus('zero.adding')
      await this.createGroup(group)
    },
    async addGroup(group) {
      await this.transitionStatus('adding')
      await this.createGroup(group)
    },
    beginDelete(groupID) {
      const group = this.groups.find((g) => g.groupID === groupID)
      return this.enqueueDelete(group)
    },
    confirmDelete() {
      return this.deleteGroup(this.deleteQueue)
    },
  },
  created: async function () {
    await this.initialize()
  },
}
</script>
