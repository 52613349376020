<template>
  <section>
    <ContentCard data-auto-id="group-add" title="Add a Group">
      <div class="py-8 px-4">
        <p class="mb-4">
          Create a group below by entering a name. You can also add a
          description, but it is not required.
        </p>
        <p>
          You can also create groups using any of our SDKs. Learn more by
          reading our API Documentation.
        </p>
        <hr class="border-gray-light border-t-2 mb-8" />
        <GroupForm
          @submit="createGroup"
          @cancel="$emit('cancel')"
          :loading="loading"
          :error="errorMessage"
        />
      </div>
    </ContentCard>
  </section>
</template>

<script>
import ContentCard from '../../../Common/ContentCard'
import GroupForm from './GroupForm'
export default {
  name: 'AddGroup',
  components: {
    ContentCard,
    GroupForm,
  },
  props: {
    loading: Boolean,
    createGroup: Function,
    errorMessage: String,
  },
}
</script>
