<template>
  <section>
    <ContentCard data-auto-id="groups-no-content" class="text-center">
      <div class="p-12">
        <img
          src="@/assets/clients-enabled.svg"
          alt="illustration of two people"
          class="inline-block mb-10"
        />
        <h3 class="text-sm mb-6 text-semibold">Create a Group</h3>
        <p>
          Groups allow you to easily share records with multiple people at the
          same time. When a new member is added to the group, everything that is
          shared with the group is instantly shared with the new member. Create
          a group below by entering a name.
        </p>
        <p>There is no limit to the number of groups you can create.</p>
        <GroupForm
          inline
          :groups="groups"
          :loading="loading"
          :error="errorMessage"
          class="text-left"
          @submit="createGroup"
        />
      </div>
    </ContentCard>
  </section>
</template>

<script>
import ContentCard from '../../../Common/ContentCard'
import GroupForm from './GroupForm'
export default {
  name: 'NoGroups',
  components: {
    ContentCard,
    GroupForm,
  },
  props: {
    createGroup: Function,
    errorMessage: String,
    loading: Boolean,
    groups: Array,
  },
}
</script>
