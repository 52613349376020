<template>
  <ToznyForm
    :on-submit="handleSubmit"
    :error="formError"
    :loading="loading"
    class="text-left"
  >
    <div class="flex items-end">
      <div id="tooltip-container" class="w-full relative float-left">
        <div id="show-tooltip" class="relative block h-8 opacity-0">
          <span class="text-red-500"
            >Group Name must be unique for this account.</span
          >
        </div>
        <div :class="inlineClasses">
          <ToznyInput
            data-auto-id="new-group-name"
            v-model="providedName"
            :class="inlineInputClass"
            class="w-full"
            name="groupName"
            id="groupName"
            label="Group Name"
            placeholder="Group Name"
            :float-label="inline"
          />
          <br />
          <ToznyInput
            data-auto-id="new-group-description"
            v-model="providedDescription"
            :class="inlineInputClass"
            class="w-full"
            name="groupDescription"
            id="groupDescription"
            label="Group Description"
            placeholder="Group Description"
            :float-label="inline"
          />
        </div>
      </div>
      <div class="flex flex-row-reverse items-center float-left">
        <ToznyButton
          id="create-group-button"
          data-auto-id="new-group-submit"
          class="m-1"
          buttonText="Create Group"
          :loading="loading"
        />
        <a
          href="#!"
          v-if="!inline"
          @click.prevent="$emit('cancel')"
          class="text-gray-darkest no-underline mx-6"
          >Cancel</a
        >
      </div>
    </div>
  </ToznyForm>
</template>

<script>
import ToznyForm from '../../../Common/ToznyForm'
import ToznyButton from '../../../Common/ToznyButton'
import ToznyInput from '../../../Common/ToznyInput'
export default {
  name: 'GroupForm',
  components: {
    ToznyForm,
    ToznyButton,
    ToznyInput,
  },
  props: {
    loading: Boolean,
    error: String,
    inline: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      providedName: '',
      providedDescription: '',
      formError: '',
    }
  },
  computed: {
    inlineClasses() {
      return this.inline ? 'flex justify-center' : ''
    },
    inlineButton() {
      return this.inline ? 'm-1' : ''
    },
    inlineInputClass() {
      return this.inline ? 'mr-4' : ''
    },
  },
  methods: {
    handleSubmit() {
      if (this.providedName && this.providedDescription) {
        const group = {
          groupName: this.providedName,
          description: this.providedDescription,
        }
        this.$emit('submit', group)
      } else {
        if (!this.providedName) {
          this.formError = 'Group Name required.'
        } else {
          this.formError = 'Group Description required.'
        }
      }
    },
  },
}
</script>

<style></style>
