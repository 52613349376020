<template>
  <section>
    <ContentCard data-auto-id="group-list" title="Groups">
      <template #extras>
        <Actions
          :actions="cardActions"
          @action="handleCardActions"
          auto-id="group-card"
        />
      </template>
      <div class="pt-8 px-4 pb-4">
        <p>Select a group to view more information about it.</p>
        <ItemTable
          data-auto-id="group-table"
          :has-more="hasMore"
          :items="groups"
          :columns="tableColumns"
          @more="$emit('more')"
        >
          <template #actions="{ itemData }">
            <Actions
              :actions="actionObject(itemData)"
              @action="handleAction"
              :auto-id="`group-actions-${itemData}`"
            />
          </template>
          <template #groupName="{ itemData }">
            <div
              :title="itemData"
              @click="handleRowClick"
              class="cursor-pointer"
            >
              {{ itemData }}
            </div>
          </template>
        </ItemTable>
      </div>
    </ContentCard>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Actions from '../../../Common/Actions'
import ContentCard from '../../../Common/ContentCard'
import ItemTable from '../../../Common/ItemTable'
export default {
  name: 'GroupList',
  components: {
    Actions,
    ContentCard,
    ItemTable,
  },
  data: () => ({
    actions: [
      {
        event: 'delete',
        altText: 'delete group',
        icon: 'delete-outline',
      },
    ],
  }),
  props: {
    groups: {
      type: Array,
      default: () => [],
      validator(items) {
        for (let item of items) {
          if (typeof item.groupName !== 'string') {
            return false
          }
        }
        return true
      },
    },
    errorMessage: String,
    hasMore: Boolean,
  },
  computed: {
    tableColumns() {
      return [
        {
          title: 'Group Name',
          target: 'groupName',
          slot: 'groupName',
        },
        {
          title: 'Actions',
          target: 'groupID',
          slot: 'actions',
          className: 'whitespace-no-wrap w-px text-center',
        },
      ]
    },
    cardActions() {
      return [
        {
          event: 'add',
          altText: 'add group',
          icon: 'add-outline',
        },
      ]
    },
  },
  methods: {
    ...mapActions('groups', [
      'transitionStatus',
      'selectGroup',
      'selectGroupName',
    ]),
    ...mapGetters('groups', ['groupID']),
    handleAction(action) {
      this.$emit(action.event, action.group)
    },
    handleCardActions(action) {
      this.$emit(action.event)
    },
    async handleRowClick(event) {
      await this.selectGroupName(event.target.title)
      let groupID = this.groupID()
      this.$router.push(`/groups/${groupID}`)
    },
    actionObject(group) {
      return [
        {
          group: group,
          event: 'delete',
          altText: 'delete group',
          icon: 'delete-outline',
        },
      ]
    },
  },
}
</script>
